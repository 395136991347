import React, { useContext } from 'react';
import GetStartedButton from '../ui/GetStartedButton';
import { MobileScreenContext } from '../Page';

export default function HeroSection({data}){
    const {heading, description, imgUrl, imgDimensions} = data;
    const { mobileScreen } = useContext(MobileScreenContext);

    if(!mobileScreen){
        return (
            <section className='flex justify-between laptop:max-w-10/12 large:max-w-3/4 mx-auto py-20'>
                <div className='pt-4'>
                    <h1 className='text-5xl font-bold'><span className='text-blue-800'>{heading[0]}</span> {heading[1]}</h1>
                    <p className='py-10 max-w-10/12 text-2xl'>{description}</p>
                    <div className='w-48 mt-4'>
                        <GetStartedButton type='light'>Get Started</GetStartedButton>
                    </div>
                </div>
                <div>
                    <img src={imgUrl} alt='hero_section_img' style={imgDimensions}/>
                </div>
            </section>
        )
    }
    else{
        return (
            <section className='max-w-11/12 mobile-tablet-one:max-w-3/4 mobile-tablet-two:max-w-[65%] mx-auto flex flex-col text-mobile-base py-3 mb-10'>
                <h1 className='text-mobile-heading-xl font-bold px-3'><span className='text-blue-800'>{heading[0]}</span> {heading[1]}</h1>
                <img src={imgUrl} alt='hero_section_img' className='py-1'/>
                <p className='mb-6 px-3'>{description}</p>
                <GetStartedButton type='light'>Get started now</GetStartedButton>
            </section>
        )
    }
}
import React, { useContext } from 'react';
import { MobileScreenContext } from '../Page';
 
export default function ContentSection({data}){
    const {type, headerContent, content} = data;
    const { mobileScreen } = useContext(MobileScreenContext);

    if(!mobileScreen){
        return (
            <section className={`rounded-2xl ${type === 'primary' && 'bg-sky-200'} py-20`}>
                <div className='laptop:max-w-[88%] large:max-w-3/4 mx-auto'>
                    
                    <div className={`grid grid-cols-12 grid-rows-1 mb-5`}>
                        <div className={`flex flex-col self-end ${type === 'primary'? 'col-start-1': 'col-start-10'} col-span-3 row-start-1`}>
                            <img src={headerContent.labelImgUrl} alt='header_label' style={headerContent.labelImgDimensions}/>
                            <h3 className='font-bold text-[1.75rem] my-5 leading-[40px]'>{headerContent.heading}</h3>
                        </div>
                        <div className={`${type === 'primary'? 'col-start-4': 'col-start-1'} col-span-9 row-start-1 ${type === 'primary' && 'justify-self-center'}`}>
                            <img src={headerContent.imgUrl} alt='header_img' style={headerContent.imgDimensions}/>
                        </div>
                    </div>
    
                    <div className='grid grid-cols-12'>
                        {content.map((text, index) => {
                            let colStart, colSpan = 'col-span-3', extraStyle={}; 
                            switch(index){
                                case 0: 
                                    colStart = 'col-start-1'; 
                                    break;
                                case 1: 
                                    colStart = 'col-start-5'; 
                                    colSpan = 'col-span-4'
                                    extraStyle = {
                                        marginLeft: '4.5rem', 
                                        maxWidth: '70%'
                                    }
                                    break;
                                case 2: 
                                    colStart = 'col-start-10'
                            }
    
                            return <p className={`${colSpan} ${colStart} max-w-[95%]`} style={extraStyle}>{text}</p>
                        })}
                    </div>
                </div>
            </section>
        )
    }
    else{
        return (
            <section className={`rounded-2xl ${type === 'primary' && 'bg-sky-200'} py-10`}>
                <div className='flex flex-col max-w-11/12 mobile-tablet-one:max-w-3/4 mobile-tablet-two:max-w-[65%] mx-auto text-[0.75rem]'>
                    <img src={headerContent.imgUrl} alt='header_img'/>
                    <div className='flex flex-col items-start'>
                        <img src={headerContent.labelImgUrl} alt='header_label' style={headerContent.labelImgDimensions} className='scale-[65%]'/>
                        <h3 className='text-mobile-heading-lg font-medium'>{headerContent.heading}</h3>
                    </div>
                    {content.map(text => <p className='py-2'>{text}</p>)}
                </div>
            </section>
        )
    }
}
import React from 'react';
import Page from '../components/Page';
import SetupSection from '../components/SetupSection';
import HeroSection from '../components/Platform/HeroSection';
import ContentSection from '../components/Platform/ContentSection';

const data = {
    heroSection: {
      heading: ['LooprIQ', 'Platform'], 
      imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/platform-large.png',
      description: 'Control all your Loopr factory floor solutions from a single cloud platform. Gain data insights, monitor performance, and deploy updates to ensure accuracy even in rapidly changing conditions.',
      imgDimensions: {
        width: '98.81rem', 
        height: '24.37rem'
      }
    }, 
    contentSection: [
      {
        type: 'primary', 
        headerContent: {
          labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/industries-search-icon.png', 
          labelImgDimensions: {
            width: '3.81rem', 
            height: '4.68rem'
          },
          heading: 'Continuous Learning', 
          imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/platform-content-01.png', 
          imgDimensions: {
            width: '56.25rem', 
            height: '25.18rem'
          }
        }, 
        content: [
          'Your factory is constantly evolving, and your quality team continually learning. Your quality software should do the same thing', 
          'Software on your factory floor is periodically re-trained on the LooprIQ Platform with new samples of your parts',
          'Your software gets smarter every week and stays current with any changes in the physical environment or the parts themselves'
        ]
      },
      {
        type: 'light', 
        headerContent: {
            labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/document-audit-icon.png', 
            labelImgDimensions: {
                width: '3.43rem', 
                height: '3.56rem'
            },
            heading: 'Custom Reporting', 
            imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/platform-content-02.png', 
            imgDimensions: {
                width: '53.75rem', 
                height: '22.37rem'
            }
        }, 
        content: [
          'Production and quality data from all your factor floor applications is collected on the platform.', 
          'Create custom reports and dashboards to analyze your quality processes & root cause failures', 
          'Measure defect rates, production targets, machine data, and calculate KPIs across your facility'
        ]
      },
      {
        type: 'primary', 
        headerContent: {
            labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/industries-search-eye-icon.png', 
            labelImgDimensions: {
              width: '5.06rem', 
              height: '3.75rem'
            },
            heading: 'Central Management', 
            imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/platform-content-03.png', 
            imgDimensions: {
                width: '59.93rem', 
                height: '28.37rem'
            }
        }, 
        content: [
          'Manage all applications deployed and running on your factory floor from a single location.', 
          'Add or remove user access, enable or disable a specific application, monitor usage time and much more',
          'Measure app performance & easily contact customer support if metrics do not meet your requirements'
        ]
      }
    ]
  }

export default function PlatformPage(){
    const {heroSection, contentSection} = data;

    return (
        <Page navbarButtonStyle='primary' selectedPage='LooprIQ Platform'>
            <HeroSection data={heroSection} />
            {contentSection.map(contentData => {
                return <ContentSection data={contentData} />
            })}
            <SetupSection type='primary' />
        </Page>
    )
}